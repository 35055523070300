.card-container h1 {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  font-size: 2em;
}

.card-container h4 {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  font-size: 1em;
}

.card-container p {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.5em;

}

.card-container a {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  text-decoration: none;
  color: #0277B6;
  padding-right: 1em;
}

.article-counter {
  opacity: 0.2;
  letter-spacing: 0.1em;
  font-weight: 300;
  font-size: 0.5em;
}

.card-container {
  overflow-y: auto;  /* Allows vertical scrolling */
  scrollbar-width: none;  /* For Firefox */
}

/* For Webkit browsers like Chrome and Safari */
.card-container::-webkit-scrollbar {
  display: none;
}
