.article-viewer {
  display: flex;
  justify-content: center;
  align-items: start;  /* Adjust alignment as needed */
  height: 100vh;
}

.card-container, .content-copy {
  width: 30%;
  margin: 20px;
  padding: 2em;
  /*border: 1px solid rgba(0,0,0,0.05);*/
  box-shadow: 0 0px 1px rgba(0,0,0,0.1);
  overflow-y: auto;
  background-color: white;
  height: 70vh;  /* Adjust as needed */
}
